import React from 'react'
import {navigate, PageProps} from 'gatsby'
import {PrivacyLayout} from '../layout/PrivacyLayout'

const PrivacyPage = ({}: PageProps) => {

  return (
    <>
      <PrivacyLayout
        activePath=""
      />
    </>
  )
}

export default PrivacyPage
